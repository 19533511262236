import emailjs from 'emailjs-com'
import Swal from 'sweetalert2';

const SERVICE_ID = "service_whoje2l";
const TEMPLATE_ID = "template_e7lgg38";
const PUBLIC_KEY = "2Lc4pHsaX7SBGRzsx";

export async function contactClient(form: any) {
  emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form, PUBLIC_KEY)
    .then((result) => {
      Swal.fire({
        icon: 'success',
        title: 'Message Sent Successfully'
      })
    }, (error) => {
      Swal.fire({
        icon:'error',
        title: 'Ooops, something went wrong',
        text: error.text,
      })
    });
  return ;
}
