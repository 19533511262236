import React, { useRef } from "react";
import {
  Admin,
  Service,
  ContactUs,
  CoreValue,
  PastResult,
  TopSection,
  TeamMembers,
  LegalSituation,
} from "./Sections";

export const Home: React.FC = () => {
  const serviceRef = useRef<HTMLElement | null>(null);
  const expertiseRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <>
      <TopSection
        refs={{
          Services: serviceRef,
          Expertise: expertiseRef,
          Contact: contactRef,
        }}
      />
      <Service serviceRef={serviceRef} />
      <CoreValue />
      {/* <PastResult /> */}
      <TeamMembers />
      <LegalSituation />
      <Admin expertiseRef={expertiseRef} />
      <ContactUs contactRef={contactRef} />
    </>
  );
};
